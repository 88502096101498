/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
global.axios = require('axios').default;
import * as bootstrap from 'bootstrap';
global.bootstrap = bootstrap;

const mfrom4post = document.getElementsByClassName("mpost");
Object.keys(mfrom4post).forEach(function(key) {
    mfrom4post[key].addEventListener('submit',function (event) {
        event.preventDefault();
        if(typeof this.dataset.before !== "undefined")
        {
            window[ this.dataset.before ]();
        }
        let functionafter = false;
        if(typeof this.dataset.after !== "undefined")
        {
            functionafter = this.dataset.after;
        }
        let curl = this.getAttribute('action');
        let  inputElements = this.getElementsByTagName("input"),
            jsonObject = {};
        for(let i = 0; i < inputElements.length; i++){
            switch (inputElements[i].type){
                case 'checkbox':
                case 'radio':
                    if(inputElements[i].checked )
                        jsonObject[inputElements[i].name] =inputElements[i].value ??= 1;
                    break;
                default:
                    jsonObject[inputElements[i].name] = inputElements[i].value;
            }
        }
        inputElements = this.getElementsByTagName("select");
        for(let i = 0; i < inputElements.length; i++){

            jsonObject[inputElements[i].name] =  inputElements[i].value;

        }
        inputElements = this.getElementsByTagName("textarea");
        for(let i = 0; i < inputElements.length; i++){
            jsonObject[inputElements[i].name] =  inputElements[i].value;
        }

        const sbtn = this.querySelector('button[type="submit"]');
        sbtn.setAttribute("disabled", "disabled");
        sbtn.blur();
        const prevtext = sbtn.textContent;
        sbtn.textContent = 'loading... ';
        const alist = this.getElementsByClassName("mwarns");

        if(alist.length>0)        {
            alist[0].classList.add("d-none");
            alist[0].classList.remove("alert-warning");
        }

        axios.post(curl, jsonObject,{ withCredentials: true } )
            .then(response => {
                if(response.data.status>0){
                    showAlert4form(alist,response.data.message , "alert-warning");
                } else  {
                    showAlert4form(alist,response.data.message , "alert-success");
                    if(typeof response.data.redirect !=='undefined')
                        window.location.href =  response.data.redirect;
                }

                if( functionafter ){
                    window[ functionafter ](response.data);
                }
            })
            .catch(error => {
                showAlert4form(alist,error.toString(),'alert-danger');
            })
            .then(response => {
                setTimeout(function(){
                    sbtn.removeAttribute('disabled');
                    sbtn.textContent = prevtext;
                }, 1600);

            });

    });
});

function showAlert4form( alist, message , cssclass ) {
    if(alist.length>0) {
        alist[0].querySelector('span').innerHTML = message;
        alist[0].classList.remove("d-none");
        alist[0].classList.add( cssclass );
    }
}

const clowarn = document.getElementsByClassName("m-cross");
Object.keys(clowarn).forEach(function (key){
    clowarn[key].addEventListener('click',function (  ){
        this.parentNode.classList.add('d-none');
    });
});

const mspinforms = document.getElementsByClassName('m-spin-form');
Object.keys(mspinforms).forEach(function (key){
    mspinforms[key].addEventListener('submit',function (event){
        event.preventDefault();
        const sbtn =   this.querySelector('button[type="submit"]') , mspin =  this.querySelector('button[data-role="mspinner"]') ;
        sbtn.classList.add('d-none');
        mspin.classList.remove('d-none');
        this.submit();
        });
});

window.myModal = new bootstrap.Modal(document.getElementById('globeSpinner'));
const mform3 = document.getElementsByClassName("m-spin-globeform");
Object.keys(mform3).forEach(function (key) {
    mform3[key].addEventListener('submit',function (event) {
        event.preventDefault();
        myModal.show();
        this.submit();
    })
});






console.log('compiled 0.07');